<template>
  <div>
    <h4 class="font-weight-bold pb-1 primary--text">Gestión</h4>
    <h5 class="font-weight-light pb-3">Calendario de Proyectos</h5>
    <v-card>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
                Hoy
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right> mdi-menu-down </v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Día</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semana</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mes</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>4 Días</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
              :weekday-format="myDayFormat"
              :weekdays="weekday"
              event-more-text="Más"
              interval-count="0"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="450px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-spacer></v-spacer>
                  <v-toolbar-title>Detalle del la actividad</v-toolbar-title>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <v-card-text>
                  <!--<span v-html="selectedEvent.name"></span><br />
                  <span v-html="selectedEvent.detalle"></span>-->
                  <v-row>
                    <v-col cols="12" lg="3"><b>Titulo:</b></v-col>
                    <v-col cols="12" lg="9">{{ selectedEvent.titulo }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="3"><b>Nº Proyecto:</b></v-col>
                    <v-col cols="12" lg="9">{{ selectedEvent.nro_proyecto }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="3"><b>Mandante:</b></v-col>
                    <v-col cols="12" lg="9">{{ selectedEvent.mandante }}</v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" lg="3"><b>Encargado:</b></v-col>
                    <v-col cols="12" lg="9">{{ selectedEvent.encargado }}</v-col>
                  </v-row>
                  <v-row v-if="selectedEvent.tipo == 2">
                    <v-col cols="12" lg="3"><b>Detalle de la actividad:</b></v-col>
                    <v-col cols="12" lg="9" style="white-space: pre">{{
                      selectedEvent.detalle
                    }}</v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  data: () => ({
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Mes",
      week: "Semana",
      day: "Día",
      "4day": "4 Días",
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    events: [],
    colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange", "grey darken-1"],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
    weekday: [1, 2, 3, 4, 5, 6, 0],
  }),
  mounted() {
    this.$refs.calendar.checkChange();
  },
  computed: {
    ...mapState(["token", "notificationSystem", "usuarioDB"]),
  },
  methods: {
    async getDataEventos() {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            token: this.token,
          },
        };
        //let filtro = this.$options.filters;

        axios.get("/eventos/evento/", config).then((response) => {
          //console.log(response.data);
          var dataResponse = response.data;
          var responsefinal = [];
          let hora = 1;

          for (let dato in dataResponse) {
            let tReg = responsefinal.filter(
              (e) =>
                e.start.substr(0, 10) == dataResponse[dato].fecha_evento.substr(0, 10)
            ).length;

            let horaFormat = (tReg + 1).toString().padStart(2, "0");
            let horaRealizacion = `T${horaFormat}:00:00`;
            responsefinal.push({
              name:
                dataResponse[dato].actividad +
                " | " +
                dataResponse[dato].responsable.nombre, //this.names[this.rnd(0, this.names.length - 1)],
              start: dataResponse[dato].fecha_evento.substr(0, 10),
              //end: dataResponse[dato].fecha_evento.substr(0, 10) + horaRealizacion,
              color: dataResponse[dato].responsable.color, //this.colors[this.rnd(0, this.colors.length - 1)],
            });
            hora++;
          }
          //this.events = responsefinal;

          resolve("ok");

          this.loading = false;
        });
      });
    },
    async getDataPendientes() {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            token: this.token,
          },
        };
        let filtro = this.$options.filters;

        axios.get("/proyectos/todosSeguimientos/", config).then((response) => {
          
          this.events = [];
          var dataResponse = response.data;
          var responsefinal = [];
          let hora = 1;
          for (let dato in dataResponse) {
            for (let concepto of dataResponse[dato].condiciones) {
              if (concepto.fecha_estimada_realizacion) {
                let tReg = this.events.filter(
                  (e) =>
                    e.start.substr(0, 10) ==
                    concepto.fecha_estimada_realizacion.substr(0, 10)
                ).length;

                let horaFormat = (tReg + 1).toString().padStart(2, "0");
                let horaRealizacion = `T${horaFormat}:00:00`;
                let check = "✅";
                if (concepto.estado == 1) {
                  check = "❌";
                }
                this.events.push({
                  tipo: 1,
                  name:
                    check +
                    " " +
                    concepto.concepto +
                    " | " +
                    dataResponse[dato].nro_proyecto +
                    " | " +
                    dataResponse[dato].presupuestos[0].mandante.nombre +
                    " | " +
                    dataResponse[dato].encargado.nombre,
                  start: concepto.fecha_estimada_realizacion.substr(0, 10),
                  nro_proyecto: dataResponse[dato].nro_proyecto,
                  titulo: concepto.concepto,
                  detalle: "",
                  mandante: dataResponse[dato].presupuestos[0].mandante.nombre,
                  encargado: dataResponse[dato].encargado.nombre,
                  color: dataResponse[dato].encargado.color,
                });
                hora++;
              }
            }

            for (let evento of dataResponse[dato].eventos) {
              if (evento.fecha_estimada_realizacion) {
                let tReg = this.events.filter(
                  (e) =>
                    e.start.substr(0, 10) ==
                    evento.fecha_estimada_realizacion.substr(0, 10)
                ).length;

                let horaFormat = (tReg + 1).toString().padStart(2, "0");
                let horaRealizacion = `T${horaFormat}:00:00`;
                let check = "✅";
                if (evento.estado == 1) {
                  check = "❌";
                }



                /*this.events.push({
                  tipo: 2,
                  name:
                    check +
                    " " +
                    evento.evento +
                    " | " +
                    dataResponse[dato].nro_proyecto +
                    " | " +
                    dataResponse[dato].presupuestos[0].mandante.nombre +
                    " | " +
                    dataResponse[dato].encargado.nombre,
                  start: evento.fecha_estimada_realizacion.substr(0, 10),

                  nro_proyecto: dataResponse[dato].nro_proyecto,
                  titulo: evento.evento,
                  detalle: evento.detalle,
                  mandante: dataResponse[dato].presupuestos[0].mandante.nombre,
                  encargado: dataResponse[dato].encargado.nombre,
                  color: dataResponse[dato].encargado.color,
                });*/

                const registroEvento = [...this.events, {
                  tipo: 2,
                  name:
                    check +
                    " " +
                    evento.evento +
                    " | " +
                    dataResponse[dato].nro_proyecto +
                    " | " +
                    dataResponse[dato].presupuestos[0].mandante.nombre +
                    " | " +
                    dataResponse[dato].encargado.nombre,
                  start: evento.fecha_estimada_realizacion.substr(0, 10),

                  nro_proyecto: dataResponse[dato].nro_proyecto,
                  titulo: evento.evento,
                  detalle: evento.detalle,
                  mandante: dataResponse[dato].presupuestos[0].mandante.nombre,
                  encargado: dataResponse[dato].encargado.nombre,
                  color: dataResponse[dato].encargado.color,
                }];

                this.events = registroEvento;
                console.log('!!...!!')
                hora++;
              }
            }
          }

          //this.events.push()
          console.log(this.events);
          resolve("ok");
          //this.desserts = responsefinal;

          this.loading = false;
        });
      });
    },
    myDayFormat(day) {
      //console.log(day.day);
      if (day.weekday == 0) return "Dom";
      else if (day.weekday == 1) return "Lun";
      else if (day.weekday == 2) return "Mar";
      else if (day.weekday == 3) return "Mie";
      else if (day.weekday == 4) return "Jue";
      else if (day.weekday == 5) return "Vie";
      else if (day.weekday == 6) return "Sab";
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
      console.log("fgjdks");
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        setTimeout(() => {
          this.selectedOpen = true;
        }, 10);
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        setTimeout(open, 10);
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    async updateRange({ start, end }) {
      console.log(start);
      console.log(end);
      /*const events = [];

      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      const days = (max.getTime() - min.getTime()) / 86400000;
      const eventCount = this.rnd(days, days + 20);

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0;
        const firstTimestamp = this.rnd(min.getTime(), max.getTime());
        const first = new Date(firstTimestamp - (firstTimestamp % 900000));
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
        const second = new Date(first.getTime() + secondTimestamp);
        console.log(allDay);

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay,
        });
      }
      console.log(events);*/

      //this.events = events;
      await this.getDataEventos();
      await this.getDataPendientes();
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
  },
};
</script>
<style>
.v-calendar-weekly__week {
  height: unset;
}
</style>
